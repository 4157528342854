import React, { useContext, useEffect, useState } from 'react'
import Enrollment from '../../../models/Enrollments'
import { LoginContext } from '../../../hooks/context/userContext';

import './CheckoutComponentV3.scss'

import RestApiClient from '../../../api/restApiClient';
import ParamsApi from '../../../models/api/ParamsApi';
import ApiResponse from '../../../models/api/ApiResponse';
import { Discount } from '../../../models/Discount';
import { Alert, Button, Carousel, Checkbox, Col, Divider, Drawer, Input, notification, Row, Skeleton, Slider, Tag, Tooltip, Typography } from 'antd';
import ProductInfo from '../../../models/ProductInfo';
import { ArrowUpOutlined, CheckCircleOutlined, CheckOutlined, EuroCircleOutlined, InfoCircleTwoTone, LoadingOutlined, PlusCircleOutlined, QuestionCircleOutlined, ReloadOutlined, RocketOutlined, SafetyCertificateTwoTone, SearchOutlined, SketchOutlined, TagsTwoTone, TrophyOutlined, WarningTwoTone } from '@ant-design/icons';
import moment from 'moment';
import groupBy from '../../../utils/GroupBy';
import { CalculateDiscountWithSteps, filterDiscounts, getProductPrice } from '../../../utils/ValidationsUtils';
import PayEnrollment from '../../../models/PayEnrollment';
import getUserIP from '../../../utils/IPUtils';
import { useStripe } from '@stripe/react-stripe-js';
import { SmileyResult } from '../..';
import parse from 'html-react-parser';
import { isPropertyAssignment } from 'typescript';

const { TextArea } = Input;

interface CheckoutComponentV3Props{
    enrollmentData: Enrollment,
    close: any
}

const CheckoutComponentV3 = (props : CheckoutComponentV3Props) : JSX.Element => {
    const loginContext = useContext(LoginContext);
    const restApiClient : RestApiClient = new RestApiClient();

    const { enrollmentData, close } = props;
    
    const [availableDiscounts, setAvailableDiscounts] = useState<Discount[]>([]);
    const [appliedDiscounts, setAppliedDiscounts] = useState<Discount[]>([]);
    const [loadingDiscounts, setLoadingDiscounts] = useState<boolean>(false);

    const [showParticularConditions, setShowParticularConditions] = useState<boolean>(false);


    const getDiscountsInfo = () : void => {
        const params : ParamsApi = {
            query: {
                sessionId: sessionStorage.getItem('token'),
            }
        }

        setLoadingDiscounts(true);
        restApiClient.fetch("GetMyDiscounts", params)
                    .then((r : ApiResponse | null)=> {
                        if (r){
                            setAvailableDiscounts(r.data);
                            getProductsForEnroll(enrollmentData.categoryId, enrollmentData.idSportCenter);
                        }
                    })
                    .finally(()=>{
                        setLoadingDiscounts(false);
                    });
    }

    const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
    const [ onePayModeData, setOnePayModeData] = useState<ProductInfo[]>([]);
    const [ multipleModeData, setMultipleModeData] = useState<ProductInfo[][]>([]);
    const [ payPerUseModeData, setPayPerUseModeData] = useState<ProductInfo[]>([]);

    const getProductsForEnroll = (categoryId : number, sportCenterId: number) : void => {
        const params : ParamsApi = {
            query: {
                idCategory: categoryId,
                idSportCenter: sportCenterId,
                sessionId: loginContext.sessionId
            }
        }

        setLoadingProducts(true);
        restApiClient.fetch("GetCheckoutProducts", params)
            .then((r : ApiResponse | null) => {
                if (r){

                    setUse3DSecure(r.data.threeDSecure);
                    var onePay = r.data.list.filter((d : ProductInfo) => d.type === "OnePay");
                    var payPerUse = r.data.list.filter((d : ProductInfo) => d.type === "PayPerUse");
                    var subscriptions = groupBy(r.data.list.filter((d : ProductInfo) => d.type === "Subscription"), "hash");

                    setOnePayModeData(onePay ?? []);
                    setMultipleModeData(subscriptions ?? []);
                    setPayPerUseModeData(payPerUse ?? []);

                    if (onePay.length > 0){
                        setProductInfo(onePay[0]);
                        setShowNoProductResult(false);
                    }else if (subscriptions.length > 0 && subscriptions[0].length > 0){
                        setProductInfo(subscriptions[0][subscriptions[0].length -1])
                        setShowNoProductResult(false);
                    }else if (payPerUse.length > 0){
                        setProductInfo(payPerUse[0])
                        setShowNoProductResult(false);
                    }else{
                        setShowNoProductResult(true);
                    }

                    setAcceptTermsAndConditions(false);

                }else{
                    setShowNoProductResult(true);
                }
            })
            .finally(()=>{setLoadingProducts(false);})
    }



    const [productInfo, setProductInfo] = useState<ProductInfo>();

    const init = () => {
        setSelectedSteps(1);
        setAcceptTermsAndConditions(false);
        setProductInfo(undefined);
        setPaymentInfo(undefined);
        setOnePayModeData([]);
        setPayPerUseModeData([]);
        setMultipleModeData([]);
        setAvailableDiscounts([]);
        setAppliedDiscounts([]);
        getDiscountsInfo();
    }


    

    const [paymentInfo, setPaymentInfo] = useState<ProductInfo>();

    const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState<boolean>(false);
    const termnsAndConditions = (v: any) : void => {
        if (v.target.checked && !acceptTermsAndConditions){
            setShowParticularConditions(true);
        }else{
            setAcceptTermsAndConditions(false);
        }
    }

    const [loadingPayment, setLoadingPayment] = useState<boolean>(false);
    const makePayment = () : void => {


        if (paymentInfo){
            setLoadingPayment(true);
            getUserIP().then(((r : any) =>{
                //if (r && r.IPv4){
            if (r && r.ip){
                    
                    let data : PayEnrollment = {
                        enrollmentId: props.enrollmentData.id,
                        productId: paymentInfo?.id,
                        quotes: selectedSteps,
                        payInitialAmount: paymentInfo?.initialPay > 0 || paymentInfo.type == "OnePay",
                        discounts: appliedDiscounts,
                        IP: r.ip
                    }

                    if (use3DSecure && (data.payInitialAmount || paymentInfo.type == "OnePay")){
                        prepareThreeDSecurePayment(data, paymentInfo);
                    }else{
                        createSubscription(data);
                    }

                }else{
                    notification.error({
                        message: 'Atención',
                        description: 'No se ha podido realizar la operación. Asegúrese de no tener un bloqueador activo en su navegador. (1)'
                    })
                    setLoadingPayment(false);
                }
            }))
        }else{
            notification.warn({
                message: 'Atención',
                description: 'Por favor, Seleccione un producto primero.',
              })
        }
    }

    const createSubscription = (data: PayEnrollment) => {
        const params : ParamsApi = {
            body: data
        }

        setLoadingPayment(true);
        restApiClient.fetch("PayEnrollment", params)
                        .then((r : ApiResponse | null)=> {
                            if (r !== null && r.code === 200){
                                props.close();
                            }
                        }).finally(()=>{
                            setLoadingPayment(false);
                        });
    }

    const prepareThreeDSecurePayment = (data: PayEnrollment, productInfo: any) : void =>{
        // Creamos el PaymentIntent en el servidor y, si ha ido mal mostramos error y si ha ido bien aparecería la modal

        const params : ParamsApi = {
            body: data
        }
        setLoadingPayment(true);
        restApiClient.fetch("ThreeDSecurePaymentStep1", params)
                .then((r : ApiResponse | null)=> {
                    if (r !== null && r.code === 200){
                        threeDSecurePayment(r.data.clientSecret, data);
                    }else{
                        setLoadingPayment(false);
                    }
                });
    }

    const getIntervalString = (interval: number, intervalCount: number) : string => {
        let result : string = '';
        switch(interval){
            case 0:
                
                result = `dia${intervalCount > 1 ? '(s)' : ''}`
            break;
            case 1:
                result = `semana${intervalCount > 1 ? '(s)' : ''}`
            break;
            case 2:
                result = `mes${intervalCount > 1 ? '(es)' : ''}`
            break;
            case 3:
                result = `año${intervalCount > 1 ? '(s)' : ''}`
            break;
        }

        return result;
    }

    //https://stripe.com/docs/payments/integration-builder
    const stripe = useStripe();
    const threeDSecurePayment = async (clientSecret: string, data: PayEnrollment) =>{
        setLoadingPayment(true);
        const payload = await stripe?.confirmCardPayment(clientSecret);

        if (payload?.error){
            console.log("Error", payload);

            var errorCode = payload?.error?.code;
            var errorMessage = payload?.error?.message;

            if (errorMessage && errorMessage.length > 0){
                notification.error({
                    message: 'Error en cargo',
                    description: `${errorMessage} (${errorCode})`
                });
            }else{
                notification.error({
                    message: 'Error en cargo',
                    description: 'No se pudo verificar su identidad por lo que el pago fue rechazado'
                });
            }

            
            setLoadingPayment(false);
        }else{
            data.payInitialAmount = false;
            createSubscription(data);
        }
    }

    const [stepSelector, setStepSelector] = useState<JSX.Element>(<></>)
    const [selectedSteps, setSelectedSteps] = useState<number>(1);
    const formatter = (value?: number) => `en ${value} meses`;
    const getSteps = (product: ProductInfo) => {
        if (product && product.type == "Subscription"){
            var subscriptionProductInfo = multipleModeData.filter((p: ProductInfo[]) => p.findIndex(pp => pp.hash == product.hash) !== -1)

            var minQuotes = subscriptionProductInfo[0][0].minQuotes;
            var maxQuotes = subscriptionProductInfo[0][subscriptionProductInfo[0].length - 1].maxQuotes;
    
            setStepSelector(<></>);
            setTimeout(()=>{
                setSelectedSteps(maxQuotes)
                setStepSelector(<div className="steps-selector">
                                    <Divider style={{backgroundColor:"white", margin: "3px 0"}}/>
                                    <span>Selecciona la cuota que más te convenga</span>

                                    <Slider
                                            style={{color:"red"}}
                                            tooltipPlacement="bottom"
                                            tipFormatter={formatter}
                                            min={minQuotes}
                                            max={maxQuotes}
                                            defaultValue={maxQuotes}
                                            onChange={setSelectedSteps}
                                        />
                                    <Divider style={{backgroundColor:"white", margin: "3px 0"}}/>
                                </div>) 
            }, 50);
            
        }else{
            setStepSelector(<></>);
            setSelectedSteps(1);
        }
    }

    useEffect(()=>{
        if (paymentInfo){
            if (paymentInfo.type === "PayPerUse"){
                var discounts = filterDiscounts(availableDiscounts, paymentInfo.type, [paymentInfo.id], enrollmentData.idSportCenter).filter((d: Discount)=>d.discountType == "percent");
                setAppliedDiscounts(discounts);
            }else{
                if (paymentInfo.type === "Subscription"){
                    var productInfoIds = multipleModeData.filter((p: ProductInfo[]) => p.findIndex(pp => pp.id == paymentInfo.id) !== -1);
                    var finalList : number[]  = [];
                    if (productInfoIds && productInfoIds.length > 0){
                        finalList = productInfoIds[0].map(p => p.id);
                    }else{
                        finalList = [paymentInfo.id];
                    }

                    setAppliedDiscounts(filterDiscounts(availableDiscounts, paymentInfo.type, finalList, enrollmentData.idSportCenter));

                }else{
                    setAppliedDiscounts(filterDiscounts(availableDiscounts, paymentInfo.type, [paymentInfo.id], enrollmentData.idSportCenter));
                }
            }
        }
    },[paymentInfo])


    useEffect(()=>{
        if (productInfo){
            getSteps(productInfo);
            setSelectedSteps(productInfo.maxQuotes)
            setAcceptTermsAndConditions(false);
            setPaymentInfo(productInfo);
        }
    },[productInfo])

    useEffect(()=>{
        init();
    },[])

    useEffect(()=>{
        init();
    },[props.enrollmentData])

    useEffect(()=>{
        //Si cambia el selector es que estamos ante un producto que permite financiación con lo que hay que buscar el producto adecuado

        if (paymentInfo && paymentInfo.type == "Subscription"){
            var subscriptionProductInfo = multipleModeData.filter((p: ProductInfo[]) => p.findIndex(pp => pp.hash == paymentInfo.hash) !== -1);
        
            if (subscriptionProductInfo && subscriptionProductInfo.length > 0){
                var newPaymentInfo = subscriptionProductInfo[0].filter((p: ProductInfo) => p.minQuotes == selectedSteps);
                setPaymentInfo(newPaymentInfo[0]);
            }
        }

    },[selectedSteps])


    const [ use3DSecure, setUse3DSecure ] = useState<boolean>(false);
    const show3DSecure = () => {
        return <div className="safety-certificate"><SafetyCertificateTwoTone className="shield" twoToneColor="#52c41a"/> ¡Su tarjeta está configurada para realizar pagos seguros!</div>
    }

    const [showNoProductResult, setShowNoProductResult] = useState<boolean>(true);
    const showNoProduct= () => {
        return <SmileyResult text={`Hola los pagos de las inscripciones están cerrados. Vuelve a intentarlo más adelante`} buttonText="Reintentar" action={()=>{ getProductsForEnroll(props.enrollmentData.categoryId, props.enrollmentData.idSportCenter) }} />
    }


    return(
        <div className="checkout-component-V3">
            <Drawer className='terms-and-conditions' title="Condiciones particulares del producto" visible={showParticularConditions} width="100%" closable={false} >
                <div className="contain">
                    {parse(paymentInfo?.termsAndConditions? paymentInfo?.termsAndConditions : '')} 
                </div>
                <Button type='primary' className="btn-terms-and-conditions-readed" onClick={()=>{setAcceptTermsAndConditions(true); setShowParticularConditions(false);}}>He leído y entiendo las condiciones particulares</Button>
            </Drawer>

            {
                loginContext.paymentMethodExpired ?
                    <Alert
                        message="Atención"
                        description={<div style={{textAlign:"center"}}>Su tarjeta de crédito está caducada. Por favor, añada un método de pago válido. Para ello pulse sobre el icono azúl de la ventana principal 'Modos de pago'. Una vez hecho el cambio de tarjeta deberá <b>volver a iniciar sesión para que los cambios surjan efecto.</b> <br/><br/> <Button type='primary' onClick={()=>{props.close()}}>Cerrar</Button></div>}
                        type="warning"
                        showIcon
                        closable
                        />
                :
                    <></>
            }

            <Skeleton active loading={loadingProducts || loadingDiscounts}>
                <div className="refresh-button">
                        <Button onClick={init} type="primary" ghost><ReloadOutlined /> Recargar listado</Button>
                </div>
                <Divider orientation="left"></Divider>
                {
                    showNoProductResult ? 
                        <Col xs={24} style={{textAlign:"center"}}>
                            {showNoProduct()}
                        </Col>
                    :
                    ''
                }
                <Row gutter={16} className="product-list">
                    {
                        onePayModeData.map((p: ProductInfo, index: number) => <ProductInfoCheckout selectedProductId={productInfo?.id} key={`one-pay-${index}`} product={p} availableDiscounts={availableDiscounts} enrollmentData={enrollmentData} setProductInfo={setProductInfo}/>)
                    }

                    {
                        multipleModeData?.map((product: ProductInfo[], index: number) => {
                            var p : ProductInfo = product[product.length -1];
                            return <ProductInfoCheckout selectedProductId={productInfo?.id} key={`one-pay-${index}`} product={p} availableDiscounts={availableDiscounts} enrollmentData={enrollmentData} setProductInfo={setProductInfo}/>;
                        })
                    }

                    {
                        payPerUseModeData.map((p: ProductInfo, index: number) => <ProductInfoCheckout selectedProductId={productInfo?.id} key={`one-pay-${index}`} product={p} availableDiscounts={availableDiscounts} enrollmentData={enrollmentData} setProductInfo={setProductInfo}/>)
                    }

                    {
                        (!showNoProductResult) 
                        ?
                            <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
                                <div className="payment-resume">
                                    {
                                        // paymentInfo ? selectedSteps * ((paymentInfo?.pvp/100) - CalculateDiscountWithSteps(paymentInfo.pvp /100, filterDiscounts(availableDiscounts, paymentInfo.type, [paymentInfo.id], enrollmentData.idSportCenter), selectedSteps)) : 0
                                    }

                                    <div className="pricing-resume">
                                            {
                                                //Si tiene pago inicial, indicamos la cantidad
                                                paymentInfo && paymentInfo.initialPay > 0 ?
                                                    <p className="initial-payment-advise">Pago inicial de <strong>{(paymentInfo?.initialPay)}€</strong> y después:</p>
                                                :
                                                    <></>
                                            }
                                            {
                                                paymentInfo ? 
                                                    <Typography.Title 
                                                            level={1} 
                                                            className="reduced-price">
                                                                €{CalculateDiscountWithSteps(paymentInfo.pvp /100, appliedDiscounts, selectedSteps)}
                                                                <sup>/{
                                                                        getIntervalString(paymentInfo.interval, paymentInfo.intervalCount)}
                                                                </sup>
                                                    </Typography.Title>
                                                :
                                                    <></>
                                            }
                                            
                                            {
                                                stepSelector
                                            }

                                            {
                                                paymentInfo?.type === "Subscription" ?
                                                    <small style={{display: "block"}}>Elegido: {selectedSteps} (Su última cuota será en {moment().add(selectedSteps,"months").format("MMMM [del] YYYY")})</small>
                                                :
                                                    <></>
                                            }

                                            {
                                                paymentInfo?.type !== "OnePay" && paymentInfo?.firstPaymentDay ?
                                                    <small style={{display: "block"}}>El primer pago de la mensualidad se realizará {moment(paymentInfo.firstPaymentDay).isAfter(moment()) ? <>el <strong>{moment(paymentInfo?.firstPaymentDay).format("DD/MM/YYYY")}</strong></> : <>ahora mismo</> }</small>
                                                :
                                                    <></>
                                            }

                                            
                                            <ul className="discounts-list">
                                            {
                                                appliedDiscounts && appliedDiscounts.length > 0 && paymentInfo  ? 
                                                    <li className="list-title">
                                                        <strong className="old-price">
                                                            €{(paymentInfo.pvp/100)}
                                                            <sup>/{
                                                                getIntervalString(paymentInfo.interval, paymentInfo.intervalCount)}
                                                            </sup>
                                                        </strong>
                                                    </li>
                                                :
                                                    <></>
                                            }
                                            
                                            {
                                                //Mostramos los descuentos. No aplican a pago por uso
                                                appliedDiscounts.map((discountInfo: Discount)=> {
                                                    return <li className="discount-info">aplicado {discountInfo.discountDescription}</li>
                                                })
                                            }
                                            </ul>
                                            
                                    </div>

                                    <div className='payment-buttons'>
                                        
                                        <div className='particular-conditions'>
                                            <Checkbox  className="checkbox" checked={acceptTermsAndConditions} onChange={termnsAndConditions}/> Acepto las <a className='show-termns-and-conditions' onClick={()=>{setShowParticularConditions(true);}}>condiciones</a>
                                        </div>

                                        <Button style={{width: "100%"}} className="make-payment" disabled={!acceptTermsAndConditions || loadingPayment} type="primary" size='large' onClick={makePayment}>
                                            {
                                                !acceptTermsAndConditions ? <><WarningTwoTone /> Acepte condiciones</> : (loadingPayment ? <><LoadingOutlined /> Pagando...</> : <><EuroCircleOutlined /> Realizar pago</>)
                                            }
                                        </Button>
                                        {
                                            use3DSecure ?  
                                                <Col xs={24} style={{textAlign:"center"}}>
                                                    {show3DSecure()}
                                                </Col>
                                            :
                                            <></>
                                        }
                                    </div>
                                    
                                </div>
                            </Col>
                        :
                            <></>
                    }
                    
                </Row>
            </Skeleton>
        </div>
    )
}

interface ProductInfoCheckoutProps{
    product: ProductInfo,
    availableDiscounts: Discount[],
    enrollmentData: Enrollment,
    key: any,
    setProductInfo: any,
    selectedProductId: number | undefined
}
const ProductInfoCheckout = (props: ProductInfoCheckoutProps) : JSX.Element => {

    const { product, availableDiscounts, enrollmentData, key, setProductInfo, selectedProductId } = props;


    const getProductTypeIcon = (type: string) : JSX.Element => {
        var result : JSX.Element = <></>
        switch(type){
            case "OnePay":
                result = <TrophyOutlined className="icon" />
                break;
            case "PayPerUse":
                result = <EuroCircleOutlined className="icon" />
                break;
            case "Subscription":
                result = <RocketOutlined className="icon" />
                break;
            default:
                result = <QuestionCircleOutlined className="icon" />
        }

        return result;
    }

    const getIntervalString = (interval: number, intervalCount: number) : string => {
        let result : string = '';
        switch(interval){
            case 0:
                
                result = `dia${intervalCount > 1 ? '(s)' : ''}`
            break;
            case 1:
                result = `semana${intervalCount > 1 ? '(s)' : ''}`
            break;
            case 2:
                result = `mes${intervalCount > 1 ? '(es)' : ''}`
            break;
            case 3:
                result = `año${intervalCount > 1 ? '(s)' : ''}`
            break;
        }

        return result;
    }

    const getProductInfo = () : JSX.Element => {
        if (!product){
            return <></>
        }

        var discounts = filterDiscounts(availableDiscounts, product.type, [product.id], enrollmentData.idSportCenter);

        if (product.type == "PayPerUse"){
            discounts = discounts.filter((discount : Discount)=> discount.discountType == "percent");
        }

        return <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                    
                    <div className={`product-info ${product?.id == selectedProductId ? 'selected' : ''}`}  key={key}>
                        {product?.id == selectedProductId ? <div className={`selected-icon ${product?.type}`}>
                            <CheckOutlined className="check-product"/>
                        </div> : ''}
                        
                        <div className={`product-type ${product.type}`}>
                            {getProductTypeIcon(product.type)}

                            <Typography.Title 
                                    level={4} 
                                    className="price">
                                        {product?.type == "Subscription" ? <small>desde</small> : 'por'} €{CalculateDiscountWithSteps(product.pvp /100, discounts, product.maxQuotes)}
                                        <sup>/{
                                                getIntervalString(product.interval, product.intervalCount)}
                                        </sup>
                                        {
                                            discounts && discounts.length > 0 
                                            ?
                                                <small className="before-discount">
                                                    <strong>
                                                        
                                                        €{(product?.pvp/100)}
                                                        <sup>/{
                                                            getIntervalString(product.interval, product.intervalCount)}
                                                        </sup>
                                                    </strong>
                                                </small>
                                            :
                                                <></>
                                        }
                                        
                            </Typography.Title>
                            <small className="availability">Disponible hasta: <strong>{moment(product.toDate).format("DD/MM/YYYY")}</strong></small>
                        </div>
                        <div className="product-body">
                            <div className="product-name">
                                {product.name}
                            </div>

                            <div className="product-description">
                                <p>
                                    {product.description}
                                </p>
                            </div>
                        </div>
                        <div className="features">
                                {product.features && product.features.length > 0 ? <div className="features-title">Extras</div> : <></>}
                                <ul>
                                {
                                    product.features?.map((feature: string, index: number)=>{
                                        if (index == 5){
                                            return <li><PlusCircleOutlined className="feature-check"/> ¡Y más cosas!</li>
                                        }else if(index < 5){
                                            return <li key={`feature-${index}`}><CheckCircleOutlined className="feature-check"/> {feature}</li>
                                        }else{
                                            return <></>
                                        }
                                    })
                                }
                                </ul>
                            </div>
                        <div className={`select-product ${product.type}`}>
                            <Button type="primary" ghost onClick={()=>{setProductInfo(product)}}>Seleccionar</Button>
                        </div>
                    </div>
                </Col>
    }

    return (<>{getProductInfo()}</>)
}

export default CheckoutComponentV3;